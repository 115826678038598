<template>
  <CInputRadioGroup
    :options="radioOptions"
    :custom="true"
    :checked.sync="radioValue"
    color="success"
    :inline="true"
  />
</template>

<script>
export default {
  name: "FilterNewCarSales",
  data() {
    return {
      selectedValue: null,
    };
  },
  computed: {
    messages() {
      return this.$store.getters.getLangMessages;
    },
    radioOptions() {
      return [
        { value: "EPH", label: "EPH" },
        { value: "ALL", label: this.messages.filters.all },
      ];
    },
    radioValue: {
      get() {
        if (!this.selectedValue) {
          this.$store.dispatch("saveFilterNewCarSalesValue", "EPH");
          return "EPH";
        }
        this.$store.dispatch("saveFilterNewCarSalesValue", this.selectedValue);
        return this.selectedValue;
      },
      set(value) {
        this.$store.dispatch("saveFilterNewCarSalesValue", value);
        this.selectedValue = value;
      },
    },
  },
};
</script>
