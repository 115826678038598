<template>
  <CInputRadioGroup
    :options="radioOptions"
    :custom="true"
    :checked.sync="radioValue"
    color="success"
    :inline="true"
  />
</template>

<script>
export default {
  name: "FilterTypeOfContract",
  data() {
    return {
      selectedValue: null,
    };
  },
  computed: {
    messages() {
      return this.$store.getters.getLangMessages;
    },
    radioOptions() {
      return [
        { value: "EW", label: this.messages.filters.ew },
        { value: "SC", label: this.messages.filters.sc }
      ];
    },
    radioValue: {
      get() {
        if (!this.selectedValue) {
          this.$store.dispatch("saveFilterTypeOfContractValue", "EW");
          return "EW";
        }
        this.$store.dispatch("saveFilterTypeOfContractValue", this.selectedValue);
        return this.selectedValue;
      },
      set(value) {
        this.$store.dispatch("saveFilterTypeOfContractValue", value);
        this.selectedValue = value;
      },
    },
  },
};
</script>

<style scoped></style>
