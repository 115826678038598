<template>
  <CRow>
    <CCol sm="6">
      <CRow class="flex--align-items-center">
        <CCol sm="3"
          ><p class="months-range-legend">{{ messages.filters.since }}</p></CCol
        >
        <CCol sm="9">
          <CSelect
            :options="options"
            :value.sync="selectedOptionSince"
            @change="setValueSince"
          />
        </CCol>
      </CRow>
    </CCol>
    <CCol sm="6">
      <CRow class="flex--align-items-center">
        <CCol sm="3"
          ><p class="months-range-legend">{{ messages.filters.upTo }}</p></CCol
        >
        <CCol sm="9">
          <CSelect
            :options="optionsUpTo"
            :value.sync="selectedOptionUpTo"
            @change="setValueUpTo"
            :disabled="restricted"
          />
        </CCol>
      </CRow>
    </CCol>
  </CRow>
</template>

<script>
import Api from "@/services/Api";

export default {
  name: "FilterMonthsRange",
  data() {
    return {
      options: null,
      optionsUpTo: null,
      lang: null,
      messages: null,
      selectedOptionSince: null,
      selectedOptionUpTo: null,
    };
  },
  methods: {
    setValueSince() {
      this.$store.dispatch("saveFilterMonthSinceValue", {
        value: this.selectedOptionSince,
      });
      this.setRestrictedValueUpTo();
    },
    setValueUpTo() {
      this.$store.dispatch("saveFilterMonthUpToValue", {
        value: this.selectedOptionUpTo,
      });
    },
    setRestrictedValueUpTo() {
      if (this.restricted) {
        this.optionsUpTo = this.options.filter((elem) => {
          if (this.selectedOptionSince === 12) {
            return elem.value === this.restricted;
          } else {
            return elem.value === this.selectedOptionSince + this.restricted;
          }
        });
        this.selectedOptionUpTo = this.optionsUpTo[0].value;
        this.setValueUpTo();
      } else {
        this.optionsUpTo = this.options;
      }
    },
  },
  props: {
    inactive: {
      type: Boolean,
      required: false,
    },
    restricted: {
      type: Number,
      required: false,
    },
  },
  created() {
    this.lang = this.$store.getters.getCurrentLang;
    this.messages = this.$store.getters.getLangMessages;

    if (!this.inactive) {
      const queryUrl = "sys/filter/months";
      Api.sendGet(queryUrl).then((response) => {
        const arrayResult = [];
        response.data.data.forEach((element) => {
          let item = {
            value: element,
            label: this.messages.filters.months[element],
          };
          arrayResult.push(item);
        });
        this.options = arrayResult;
        this.selectedOptionSince = arrayResult[0].value;
        this.selectedOptionUpTo = arrayResult[0].value;
        this.setValueSince();
        this.setValueUpTo();
        this.setRestrictedValueUpTo();
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.months-range-legend {
  margin-bottom: 1rem;
  white-space: nowrap;
  word-wrap: nowrap;
}
</style>
