<template>
  <CCol class="pt-1">
    <CRow>
      <CSwitch
        @update:checked="setValue"
        class="mr-1"
        color="success"
        shape="pill"
      />
      <span class="ml-2">{{ messages.filters.monthlyDetail }}</span>
    </CRow>
  </CCol>
</template>

<script>
export default {
  name: "FilterMonthlyDetail",
  computed: {
    messages() {
      return this.$store.getters.getLangMessages;
    },
  },
  methods: {
    setValue(value) {
      console.log("saveFilterIsCumulativeValue", !value);
      const numValue = value ? 0 : 1;
      this.$store.dispatch("saveFilterIsCumulativeValue", {
        value: numValue,
      });
    },
  },
  created() {
    this.$store.dispatch("saveFilterIsCumulativeValue", {
      value: 1,
    });
  },
};
</script>

<style scoped></style>
