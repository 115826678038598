<template>
  <div>
    <CForm @submit="submitFilterValues">
      <CCard>
        <CButton
          @click="cardCollapse = !cardCollapse"
          tag="button"
          color="link"
          class="flex flex--space-between text-left shadow-none card-header"
        >
          <h5 class="m-0">Filtros</h5>
          <CIcon :name="`cil-chevron-${cardCollapse ? 'top' : 'bottom'}`" />
        </CButton>
        <CCollapse :show="cardCollapse">
          <CCardBody>
            <CRow>
              <CCol sm="12" xl="12" xxl="8">
                <label class="filter-label">{{ messages.filters.area }}</label>
                <!--                <FiltersAreaCombo :activeElements="activeItems" :pre-selected="preSelectedArea" />-->
                <FiltersAreaCombo
                  :activeElements="activeItems"
                  :all-countries="true"
                />
                <span
                  v-if="areaError"
                  class="error-message error-message--bottom"
                  >{{ messages.validation.filterAreaCombo }}</span
                >
              </CCol>
              <CCol sm="12" lg="4" xxl="1">
                <label class="filter-label">FY</label>
                <FilterFY />
              </CCol>
              <CCol sm="12" lg="8" xxl="3">
                <label class="filter-label">{{
                  messages.filters.period
                }}</label>
                <FilterMonthsRange />
              </CCol>
              <!--            </CRow>-->
              <!--            <CRow>-->
              <CCol class="mb-3" sm="12" xl="3">
                <label class="filter-label">{{
                  messages.filters.cumulativeOrNot
                }}</label>
                <FilterMonthlyDetail />
              </CCol>
              <!--              <CCol class="mb-3" sm="6" xl="3">
                <label class="filter-label">{{
                  messages.filters.concessionGroup
                }}</label>
                <filter-aspm-spm />
              </CCol>-->
              <CCol class="mb-3" sm="12" xl="6">
                <label class="filter-label">{{
                  messages.filters.typeOfContract
                }}</label>
                <filter-type-of-contract />
              </CCol>
              <CCol class="mb-3" sm="12" xl="3">
                <label class="filter-label">New Car Sales</label>
                <filter-new-car-sales />
              </CCol>
            </CRow>
          </CCardBody>
          <CCardFooter>
            <CButton type="submit" color="primary">Mostrar</CButton>
          </CCardFooter>
        </CCollapse>
      </CCard>
    </CForm>
    <CRow v-if="showLoader" class="justify-content-center">
      <Loader
        width="100px"
        height="100px"
        fill-color="#c2c2c2"
        cssClass="fadeIn anim-speed--1s"
      />
    </CRow>
    <CRow v-if="showTable" class="fadeIn anim-speed--2s">
      <CCol sm="12">
        <table-tracking-ew-sc
          :table-data="tableData"
          :contract-type="contractTypeValue"
          :ncs-type="ncsTypeValue"
        >
        <span slot="area"> {{ selectedArea }} {{ selectedArea && ' - ' }} </span>
        <span slot="period">{{ selectedPeriod }} / </span>
        <span slot="fiscalYear">{{ selectedFY }}</span>
      </table-tracking-ew-sc>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import FiltersAreaCombo from "@/components/organisms/FiltersAreaCombo";
import FilterFY from "@/components/molecules/FilterFY";
import FilterMonthsRange from "@/components/molecules/FilterMonthsRange";
import FilterMonthlyDetail from "@/components/molecules/FilterMonthlyDetail";
import FilterTypeOfContract from "@/components/molecules/FilterTypeOfContract";
import FilterNewCarSales from "@/components/molecules/FilterNewCarSales";
import TableTrackingEwSc from "@/components/organisms/TableTrackingEwSc";
import Loader from "@/components/atoms/Loader";
import formatFiscalYear from "@/helpers/formatFiscalYear";
import getAreaName from "@/helpers/getAreaName";

import Api from "@/services/Api";
export default {
  name: "TrackingEwSc",
  components: {
    TableTrackingEwSc,
    Loader,
    FiltersAreaCombo,
    FilterFY,
    FilterMonthsRange,
    FilterMonthlyDetail,
    FilterTypeOfContract,
    FilterNewCarSales,
  },
  data() {
    return {
      cardCollapse: true,
      areaError: false,
      showLoader: false,
      showTable: false,
      activeItems: ["countries", "regionals", "aspms", "concessions"],
      // preSelectedArea: { category: "countries", value: "PRT" },
      tableData: null,
      contractTypeValue: null,
      ncsTypeValue: null,
      selectedArea: null,
      selectedFY: null,
      selectedPeriod: null,
    };
  },
  computed: {
    messages() {
      return this.$store.getters.getLangMessages;
    },
  },
  methods: {
    submitFilterValues(event) {
      event.preventDefault();
      if (!this.$store.getters.getFiltersAreaComboParams) {
        this.areaError = true;
      } else {
        this.areaError = false;
        const params = {
          ...this.$store.getters.getFiltersAreaComboParams,
          fiscalYear: this.$store.getters.getFilterFYValue,
          sinceMonth: this.$store.getters.getFilterMonthSinceValue,
          upToMonth: this.$store.getters.getFilterMonthUpToValue,
          isCumulative: this.$store.getters.getFilterIsCumulativeValue,
          // concessionsGroup: this.$store.getters.getFilterAspmSpmValue,
          contractType: this.$store.getters.getFilterTypeOfContractValue,
          ncsType: this.$store.getters.getFilterNewCarSalesValue,
        };
        this.makeApiQuery(params);
      }
    },
    makeApiQuery(params) {
      // Show the loader and hide the table until data is received
      this.showLoader = true;
      this.showTable = false;
      // API call sending parameters
      Api.sendPost("commercial/contractTracking", params).then((response) => {
        if (response.status === 200) {
          if (response.data.code === 200) {
            this.setTableTitle(params)
            // Hide the loader and sow the table
            this.showLoader = false;
            this.showTable = true;
            // Set the processed data variable to send to the DataTable component
            this.tableData = response.data.data;
            this.contractTypeValue = params.contractType;
            this.ncsTypeValue = params.ncsType;
          }
        }
      });
    },
    setTableTitle(params) {
      this.selectedArea = getAreaName(params, this.messages);
      this.selectedFY = formatFiscalYear(params.fiscalYear);
      const sinceMonth = this.messages.filters.months[params.sinceMonth];
      const upToMonth = this.messages.filters.months[params.upToMonth]
      this.selectedPeriod = sinceMonth === upToMonth ? sinceMonth : `de ${sinceMonth} a ${upToMonth}`;
    },
  },
};
</script>

<style lang="scss">
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  background-color: #7dc74e;
  border-color: #64ac36;
}
</style>
