<template>
  <CRow>
    <CCol v-if="activeElements.includes(countriesCategory)">
      <FilterArea
        :selectedItem="setCategorySelected"
        :category="countriesCategory"
        :queryUrl="allCountries ? countriesAllUrl : countriesUrl"
        :placeholder="countriesPlaceholder"
        :allCountries="allCountries"
        :pre-selected="preSelected"
      />
    </CCol>
    <CCol v-if="activeElements.includes(regionsCategory)">
      <FilterArea
        :selectedItem="setCategorySelected"
        :category="regionsCategory"
        :queryUrl="regionsUrl"
        :placeholder="regionsPlaceholder"
        :pre-selected="preSelected"
      />
    </CCol>
    <CCol v-if="activeElements.includes(aspmsCategory)">
      <FilterArea
        :selectedItem="setCategorySelected"
        :category="aspmsCategory"
        :queryUrl="aspmsUrl"
        :placeholder="aspmsPlaceholder"
        :pre-selected="preSelected"
      />
    </CCol>
    <CCol v-if="activeElements.includes(concessionsCategory)">
      <FilterArea
        :selectedItem="setCategorySelected"
        :category="concessionsCategory"
        :queryUrl="concessionsUrl"
        :placeholder="concessionsPlaceholder"
        :pre-selected="preSelected"
      />
    </CCol>
  </CRow>
</template>

<script>
const FilterArea = () =>
  import(
    /* webpackChunkName: "filter-general-area" */ "@/components/molecules/FilterArea"
  );
export default {
  name: "FiltersAreaCombo",
  data() {
    return {
      countriesCategory: "countries",
      countriesUrl: "sys/filter/countries",
      countriesAllUrl: "sys/filter/countries/all",
      regionsCategory: "regionals",
      regionsUrl: "sys/filter/regionals",
      concessionsCategory: "concessions",
      concessionsUrl: "sys/filter/concessions",
      aspmsCategory: "aspms",
      aspmsUrl: "sys/filter/aspms",
    };
  },
  computed: {
    setCategorySelected() {
      return this.$store.state.filterAreaCategory;
    },
    messages() {
      return this.$store.getters.getLangMessages;
    },
    countriesPlaceholder() {
      return this.$store.getters.getLangMessages.filters.countriesPlaceholder;
    },
    regionsPlaceholder() {
      return this.$store.getters.getLangMessages.filters.regionsPlaceholder;
    },
    concessionsPlaceholder() {
      return this.$store.getters.getLangMessages.filters.concessionsPlaceholder;
    },
    aspmsPlaceholder() {
      return this.$store.getters.getLangMessages.filters.aspmsPlaceholder;
    },
  },
  components: {
    FilterArea,
  },
  props: {
    activeElements: {
      type: Array,
      required: true,
    },
    allCountries: {
      type: Boolean,
      required: false,
    },
    preSelected: {
      type: Object,
      required: false,
    },
  },
};
</script>
