<template>
  <CCard>
    <CCardHeader class="flex flex--space-between flex--align-items-baseline">
      <span class="col-xs-12 col-md-9 mb-2 mb-md-0 p-0">
        <strong>{{ messages.tables.results }} </strong>
        <slot name="area"></slot>
        <slot name="period"></slot>
        <slot name="fiscalYear"></slot>
      </span>
      <CButton color="light" class="gtm-tag-export" @click="exportHTMLToExcel">
        {{ messages.tables.export.toUpperCase() }}
        <LogoExcel />
      </CButton>
    </CCardHeader>
    <CCardBody>
      <CDataTable
        hover
        outlined
        border
        :table-filter="{
          label: messages.tables.filter,
          placeholder: `${messages.tables.search} ...`,
        }"
        cleaner
        :noItemsView="{
          noItems: messages.tables.noItems,
          noResults: messages.tables.noResults,
        }"
        clickableRows
        id="excelArea"
        :fields="tableFields"
        :items="tableItems"
      >
        <template #thead-top>
          <tr class="thead-top">
            <th></th>
            <th></th>
            <th
              v-for="(cell, index) in theadCells"
              :key="index"
              :colspan="cell.colspan"
            >
              {{ cell.description }}
            </th>
          </tr>
        </template>
        <template #colSpan="{ item }">
          <td :colspan="item.colSpan"></td>
        </template>
      </CDataTable>
    </CCardBody>
  </CCard>
</template>

<script>
import formatNum from "@/utils/formatNum";
import ExportHTML2Excel from "@/helpers/ExportHTML2Excel";
import LogoExcel from "@/components/atoms/LogoExcel";
export default {
  name: "TableTrackingEwSc",
  components: {
    LogoExcel,
  },
  props: {
    tableData: {
      type: Object,
      required: true,
    },
    contractType: {
      type: String,
      required: true,
    },
    ncsType: {
      type: String,
      required: true,
    },
  },
  computed: {
    messages() {
      return this.$store.getters.getLangMessages;
    },
    firstMonthlyLevel() {
      return this.tableData.concessionContents[0].monthlyContentList;
    },
    totalConcession() {
      return this.tableData.concessionContents[0].totalConcessionContent;
    },
    countryCode() {
      return this.tableData.countryContents[0].countryCode;
    },
    theadCells() {
      const firstMonthlyLevel = this.firstMonthlyLevel;
      const cells = [];
      firstMonthlyLevel.map((month) => {
        cells.push({
          colspan: Object.entries(month.basicContent).length,
          description: `${this.messages.filters.months[
            month.month
          ].toUpperCase()} ${month.fiscalYear}`,
        });
      });
      const totalConcession = this.totalConcession;
      cells.push({
        colspan: Object.entries(totalConcession).length,
        description: "TOTAL CONCES",
      });
      return cells;
    },
    tableFields() {
      const firstMonthlyLevel = this.firstMonthlyLevel;
      const cells = [
        { key: "code", label: "CÓDIGO" },
        { key: "name", label: this.messages.common.businessName.toUpperCase() },
      ];
      firstMonthlyLevel.map((month, i) => {
        Object.keys(month.basicContent).map((key) => {
          cells.push({
            key: `${key}${i}`,
            label: this.getColumnLabel(key),
          });
        });
      });
      const totalConcession = this.totalConcession;
      Object.keys(totalConcession).map((key) => {
        cells.push({
          key: `${key}${firstMonthlyLevel.length}`,
          label: this.getColumnLabel(key),
        });
      });
      return cells;
    },
    tableItems() {
      const rows = [];
      const {
        concessionContents,
        aspmContents,
        spmContents,
        regionalContents,
        countryContents,
      } = this.tableData;

      concessionContents.map(
        ({
          concessionCode,
          businessName,
          monthlyContentList,
          totalConcessionContent,
        }) => {
          const row = this.getRow(
            concessionCode,
            businessName,
            monthlyContentList,
            totalConcessionContent
          );
          rows.push(row);
        }
      );

      aspmContents.map(
        ({ aspm, monthlyContentList, totalConcessionContent }) => {
          const row = this.getRow(
            "TOTAL",
            aspm,
            monthlyContentList,
            totalConcessionContent,
            "totals-ew-sc"
          );
          rows.push(row);
        }
      );

      spmContents.map(({ spm, monthlyContentList, totalConcessionContent }) => {
        const row = this.getRow(
          "TOTAL",
          spm,
          monthlyContentList,
          totalConcessionContent,
          "totals-ew-sc"
        );
        rows.push(row);
      });

      regionalContents.map(
        ({ regionalCode, monthlyContentList, totalConcessionContent }) => {
          const row = this.getRow(
            "TOTAL",
            this.messages.areaByCode[regionalCode].toUpperCase(),
            monthlyContentList,
            totalConcessionContent,
            "totals-ew-sc"
          );
          rows.push(row);
        }
      );

      countryContents.map(
        ({ countryCode, monthlyContentList, totalConcessionContent }) => {
          const row = this.getRow(
            "TOTAL",
            this.messages.areaByCode[countryCode].toUpperCase(),
            monthlyContentList,
            totalConcessionContent,
            "totals-ew-sc"
          );
          rows.push(row);
        }
      );
      return rows;
    },
  },
  methods: {
    exportHTMLToExcel() {
      ExportHTML2Excel(
        document.querySelector("#excelArea table.table"),
        this.messages.purchaseDataDetail.title
      );
    },
    getColumnLabel(key) {
      const label =
        key === "ncs"
          ? this.messages.trackingEwSc.ncs(this.ncsType)
          : key !== "totalValue" &&
            key !== "campaignValue" &&
            key !== "privateValue" &&
            key !== "totalInvoiced" &&
            key !== "totalPrivateInvoiced"
          ? this.messages.trackingEwSc[key]
          : this.messages.trackingEwSc[key](this.contractType);
      return label;
    },
    getCompletionClass(value) {
      const RESULTS = {
        EW: {
          ESP: {
            min: 41.5,
            max: 42.7,
          },
          PRT: {
            min: 15.6,
            max: 16.1,
          },
        },
        SC: {
          ESP: {
            min: 16.6,
            max: 17.1,
          },
          PRT: {
            min: 17.8,
            max: 18.4,
          },
        },
      };
      const intervals = RESULTS[this.contractType][this.countryCode];
      let result = "";
      if (value < intervals.min) {
        result = "result--low";
      } else if (value >= intervals.min && value < intervals.max) {
        result = "result--medium";
      } else {
        result = "result--high";
      }
      return result;
    },
    processDataRow(concept, index) {
      const conceptName = concept[0],
        conceptValue =
          conceptName === "completion"
            ? isNaN(Number(concept[1])) || !isFinite(concept[1])
              ? "-"
              : formatNum.get(concept[1], 2)
            : formatNum.get(concept[1], 2),
        suffix =
          conceptName === "totalInvoiced" ||
          conceptName === "totalPrivateInvoiced"
            ? "€"
            : conceptName === "completion" &&
              !isNaN(Number(concept[1])) &&
              isFinite(concept[1])
            ? "%"
            : "";
      const cells = {};
      cells[`${conceptName}${index}`] = `${conceptValue}${suffix}`;
      const cellClasses = {};
      if (conceptName === "completion" && !isNaN(concept[1])) {
        cellClasses[`${conceptName}${index}`] = this.getCompletionClass(
          concept[1]
        );
      }

      return {
        cells: cells,
        cellClasses: cellClasses,
      };
    },
    getRow(firstCell, secondCell, months, total, rowClasses) {
      const baseRow = {
        code: firstCell,
        name: secondCell,
      };

      if (rowClasses) baseRow._classes = rowClasses;

      let row = {},
        cellClassesObj;

      // Month Cells
      months.map(({ basicContent }, i) => {
        Object.entries(basicContent).map((concept) => {
          row = Object.assign(baseRow, this.processDataRow(concept, i).cells);
          const currentCellClasses = { ...cellClassesObj };
          cellClassesObj = Object.assign(
            currentCellClasses,
            this.processDataRow(concept, i).cellClasses
          );
        });
      });

      // Total Cells
      Object.entries(total).map((concept) => {
        const i = months.length;
        row[`${concept[0]}${i}`] = concept[1];
        row = Object.assign(baseRow, this.processDataRow(concept, i).cells);
        const currentCellClasses = { ...cellClassesObj };
        cellClassesObj = Object.assign(
          currentCellClasses,
          this.processDataRow(concept, i).cellClasses
        );
      });

      row._cellClasses = { ...cellClassesObj };

      return row;
    },
  },
};
</script>

<style lang="scss">
thead {
  .thead-top {
    text-align: center;
    font-weight: bold;
    color: #ffffff;
    background: #666666;
    height: 32px;
    vertical-align: middle;
    border-left: 1px solid #dddddd;
  }
  .headCell {
    font-weight: bold;
    background: #dddddd;
    height: 32px;
    vertical-align: middle;
    text-align: center;

    &.dealer {
      text-align: left;
    }
  }
}

.totals-ew-sc {
  font-weight: 700;
  border-bottom: 1px solid #d8dbe0;

  td:first-child {
    border-right-color: transparent;
  }
}
$low: #da2840;
$medium: #fac928;
$high: #60d1b1;

.result {
  &--low {
    color: $low;
  }

  &--medium {
    color: $medium;
  }

  &--high {
    color: $high;
  }
}
</style>
