<template>
  <CSelect :options="options" :value.sync="selectedOption" @change="setValue" />
</template>

<script>
import Api from "@/services/Api";

export default {
  name: "FilterFY",
  data() {
    return {
      options: null,
      lang: null,
      messages: null,
      selectedOption: null,
    };
  },
  methods: {
    setValue() {
      this.$store.dispatch("saveFilterFYValue", {
        value: this.selectedOption,
      });
    },
  },
  created() {
    this.lang = this.$store.getters.getCurrentLang;
    this.messages = this.$store.getters.getLangMessages;
    const queryUrl = "sys/filter/fy/5";
    Api.sendGet(queryUrl).then((response) => {
      const arrayResult = [];
      response.data.data.forEach((element) => {
        let item = {
          value: element.year,
          label: element.code,
        };
        arrayResult.push(item);
      });
      this.options = arrayResult;
      this.selectedOption = arrayResult[0].value;
      this.setValue();
    });
  },
};
</script>
