export default (elem, title, heading) => {
  const table = elem.cloneNode(true);

  if (heading) {
    const headingRow = `<tr><th colspan="${table.rows[1].cells.length}">${heading}</th></tr>`;
    table.innerHTML = headingRow + table.innerHTML;
  }

  // Iterate over the rows of the table and remove any empty rows
  if (table.rows) {
    for (let i = table.rows.length - 1; i >= 0; i--) {
      const row = table.rows[i];
      let isEmpty = true;
      for (let j = 0; j < row.cells.length; j++) {
        if (row.cells[j].textContent.trim() !== "") {
          isEmpty = false;
          break;
        }
      }
      if (isEmpty) {
        table.deleteRow(i);
      }
    }
  } 

  const uri = "data:application/vnd.ms-excel;base64,",
    template =
      '<html lang="es-ES" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta http-equiv="content-type" content="application/vnd.ms-excel; charset=UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
    base64 = function (s) {
      return window.btoa(unescape(encodeURIComponent(s)));
    },
    format = function (s, c) {
      return s.replace(/{(\w+)}/g, function (m, p) {
        return c[p];
      });
    };

  const ctx = {
    worksheet: title || "Worksheet",
    table: table.innerHTML,
  };
  // window.location.href = uri + base64(format(template, ctx)); // Not compatible with all browsers

  // Compatible with all browsers to set filename
  // Create a new anchor element
  const link = document.createElement("a");
  // Set its href attribute to the data URI
  link.href = uri + base64(format(template, ctx));
  // Set its download attribute to the desired file name
  link.download = (title || "Worksheet") + ".xls";
  // Click the link to initiate the download
  link.click();
};
